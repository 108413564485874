import React, { useEffect } from 'react'


import Layout from '../components/layout'
import { Contact } from '../components/contact'
import { SmallTitlePage } from '../components/common/smallTitlePage'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'



const TorunKontakt = () => {

    const data = useStaticQuery(graphql`
    {
        file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              
            )
          }
        }
      }
  `)

  return (
    <Layout torun>
      <Seo
        title="Torun kontakt"
        description="Kontakt w BodyMed Group Torun."
        url="https://bodymedgroup.pl/torun-kontakt"
      />
      <SmallTitlePage
        title='Kontakt' 
      />
      <Contact torun/>
    </Layout>
  )
}

export default TorunKontakt
